import React, { useState, useEffect } from 'react';
import api from '../api';

const Home = ({ permission }) => {
  const [CurrentSmsBalance, setCurrentSmsBalance] = useState(0);
  const [TotalSmsBalance, setTotalSmsBalance] = useState(0);
  const [GrantedSmsBalance, setGrantedSmsBalance] = useState(0);
  const [UsedSmsBalance, setUsedSmsBalance] = useState(0);
  const [VerifiedSmsBalance, setVerifiedSmsBalance] = useState(0);
  const [VerificationSmsBalance, setVerificationSmsBalance] = useState(0);
  const [TextSmsBalance, setTextSmsBalance] = useState(0);
  const [SmsMessageCountYear, setSmsMessageCountYear] = useState(0);
  const [SmsMessageYear, setSmsMessageYear] = useState(0);
  const [SmsVerificationCountYear, setSmsVerificationCountYear] = useState(0);
  const [SmsVerificationYear, setSmsVerificationYear] = useState(0);
  const [SmsPackageCountYear, setSmsPackageCountYear] = useState(0);
  const [SmsPackageYear, setSmsPackageYear] = useState(0);
  useEffect(() => {
    // localStorage.setItem('chart_sms_message',`{"year":0,"labels":[""],"values":[0]}`);
    // localStorage.setItem('chart_sms_verification',`{"year":0,"labels":[""],"values":[0]}`);
    api.get(`/SystemPackageBalance/`).then((e) => {
      if (permission) {
        setGrantedSmsBalance(e.data.granted);
      }
      setCurrentSmsBalance(e.data.current);
      setTotalSmsBalance(e.data.total);
      setUsedSmsBalance(e.data.used);
      setVerifiedSmsBalance(e.data.verified);
      setVerificationSmsBalance(e.data.verification);
      setTextSmsBalance(e.data.text);
      localStorage.setItem('chart_sms_message', JSON.stringify(e.data.chart_sms_message));
      localStorage.setItem('chart_sms_verification', JSON.stringify(e.data.chart_sms_verification));
      localStorage.setItem('chart_sms_verified', JSON.stringify(e.data.chart_sms_verified));
      localStorage.setItem('chart_sms_package', JSON.stringify(e.data.chart_sms_package));

    }).catch((e)=>{
      e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
      window.show_message(e.message,'bg-gradient-danger','text-white');
    });
    let SmsPackage = JSON.parse(localStorage.getItem('chart_sms_package'));
    setSmsPackageYear(SmsPackage.year);
    setSmsPackageCountYear(SmsPackage.values.reduce((total, num) => total + num, 0));
    let SmsMessage = JSON.parse(localStorage.getItem('chart_sms_message'));
    setSmsMessageYear(SmsMessage.year);
    setSmsMessageCountYear(SmsMessage.values.reduce((total, num) => total + num, 0));
    let Verification = JSON.parse(localStorage.getItem('chart_sms_verification'));
    let Verified = JSON.parse(localStorage.getItem('chart_sms_verified'));
    setSmsVerificationYear(Verification.year);
    setSmsVerificationCountYear(Verification.values.reduce((total, num) => total + num, 0));
    let FalidVerified = {values:[]};
    for (let index = 0; index < Verification.values.length; index++) {
      FalidVerified.values.push(Verification.values[index]-Verified.values[index] || 0);
    }
    var ctx = document.getElementById("chart-bars").getContext("2d");
    new window.Chart(ctx, {
      type: "bar",
      data: {
        labels: SmsPackage.labels,
        datasets: [{
          label: "رسالة ممنوحه",
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: "#43A047",
          data: SmsPackage.values,
          barThickness: 'flex'
        },],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: '#e5e5e5'
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 500,
              beginAtZero: true,
              padding: 10,
              font: {
                size: 14,
                lineHeight: 2
              },
              color: "#737373"
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5]
            },
            ticks: {
              display: true,
              color: '#737373',
              padding: 10,
              font: {
                size: 14,
                lineHeight: 2
              },
            }
          },
        },
      },
    });


    var ctx2 = document.getElementById("chart-line").getContext("2d");

    var gradientStroke1 = ctx2.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, 'rgba(203,12,159,0.2)');
    gradientStroke1.addColorStop(0.2, 'rgba(72,72,176,0.0)');
    gradientStroke1.addColorStop(0, 'rgba(203,12,159,0)'); //purple colors

    var gradientStroke2 = ctx2.createLinearGradient(0, 230, 0, 50);

    gradientStroke2.addColorStop(1, 'rgba(20,23,39,0.2)');
    gradientStroke2.addColorStop(0.2, 'rgba(72,72,176,0.0)');
    gradientStroke2.addColorStop(0, 'rgba(20,23,39,0)'); //purple colors

    new window.Chart(ctx2, {
      type: "line",
      data: {
        labels: Verification.labels,
        datasets: [{
          label: "رسالة تحقق",
          tension: 0.4,
          pointRadius: 3,
          pointBackgroundColor: "#cb0c9f",
          pointBorderColor: "transparent",
          borderColor: "#cb0c9f",
          borderWidth: 3,
          backgroundColor: gradientStroke1,
          fill: true,
          data: Verification.values,
          maxBarThickness: 6

        },
        {
          label: "رسالة تحقق ناجحة",
          tension: 0.4,
          pointBackgroundColor: "#43a047",
          pointBorderColor: "transparent",
          borderColor: "#43a047",
          borderWidth: 3,
          backgroundColor: gradientStroke1,
          fill: true,
          data: Verified.values,
          maxBarThickness: 6

        },
        {
          label: "رسالة تحقق فاشلة",
          tension: 0.4,
          pointBackgroundColor: "#fd9810",
          pointBorderColor: "transparent",
          borderColor: "#fd9810",
          borderWidth: 3,
          backgroundColor: gradientStroke1,
          fill: true,
          data: FalidVerified.values,
          maxBarThickness: 6

        },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: '#c1c4ce5c'
            },
            ticks: {
              display: true,
              padding: 10,
              color: '#b2b9bf',
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: 'normal',
                lineHeight: 2
              },
            }
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
              color: '#c1c4ce5c'
            },
            ticks: {
              display: true,
              color: '#b2b9bf',
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: 'normal',
                lineHeight: 2
              },
            }
          },
        },
      },
    });

    var ctx3 = document.getElementById("chart-line-tasks").getContext("2d");

    new window.Chart(ctx3, {
      type: "line",
      data: {
        labels: SmsMessage.labels,
        datasets: [{
          label: "رسالة نصية",
          tension: 0,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "#575f9a",
          pointBorderColor: "transparent",
          borderColor: "#575f9a",
          backgroundColor: "transparent",
          fill: true,
          data: SmsMessage.values,
          maxBarThickness: 6

        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: '#c1c4ce5c'
            },
            ticks: {
              display: true,
              padding: 10,
              color: '#b2b9bf',
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: 'normal',
                lineHeight: 2
              },
            }
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
              color: '#c1c4ce5c'
            },
            ticks: {
              display: true,
              color: '#b2b9bf',
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: 'normal',
                lineHeight: 2
              },
            }
          },
        },
      },
    });


  }, []);
  return (

    <div className="col-lg-12 position-relative z-index-2">

      <div className="ms-3 p-1 my-2">
        <h3 className="mb-0 h4 font-weight-bolder">الرئيسية</h3>
      </div>

      <div className="row">
        <div className={`col-lg-${permission ? '3':'4'} col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">message</i>
                </div>
                <div>
                  <h4 className="mb-0">{CurrentSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">الرصيد الحالي من الرسائل</span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-${permission ? '3':'4'} col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">sms</i>
                </div>
                <div>
                  <h4 className="mb-0">{TotalSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل {permission ? 'التي تم شرائها':'الممنوحه'} </span></p>
            </div>
          </div>
        </div>
        {permission ?
          <>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card  mb-2">
                <div className="card-header p-2 ps-3">
                  <div className="d-flex justify-content-between">
                    <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                      <i className="material-symbols-rounded opacity-10">group</i>
                    </div>
                    <div>
                      <h4 className="mb-0">{GrantedSmsBalance}</h4>
                    </div>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                <div className="card-footer p-2 ps-3">
                  <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل التي نم منحها للمستخدمين  </span></p>
                </div>
              </div>
            </div>
          </> : <>

          </>
        }
        <div className={`col-lg-${permission ? '3':'4'} col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">send</i>
                </div>
                <div>
                  <h4 className="mb-0">{UsedSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل التي تم استهلاكها {permission && 'من قبل المستخدمين'} </span></p>
            </div>
          </div>
        </div>
      </div>
{/*       
      <div className="row mt-3">
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-primary shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">verified</i>
                </div>
                <div>
                  <h4 className="mb-0">{VerificationSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder"> اجمالي رسائل التحقق </span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-success shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">check</i>
                </div>
                <div>
                  <h4 className="mb-0">{VerifiedSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي رسائل التحقق الناجحة </span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-warning shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">close</i>
                </div>
                <div>
                  <h4 className="mb-0">{VerificationSmsBalance-VerifiedSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي رسائل التحقق الفاشلة </span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-info shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">email</i>
                </div>
                <div>
                  <h4 className="mb-0">{TextSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل المباشرة </span></p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row mt-2">
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">leaderboard</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">
                  <h6 className="mb-0">الرسائل الممنوحه خلال عام {SmsPackageYear} باجمالي {SmsPackageCountYear}</h6>
                </div>

              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
                <canvas id="chart-bars" className="chart-canvas" height="170"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">show_chart</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">
                  <h6 className="mb-0">رسائل التحقق خلال عام {SmsVerificationYear} باجمالي {SmsVerificationCountYear}</h6>
                </div>

              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
                <canvas id="chart-line" className="chart-canvas" height="170"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">insights</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">
                  <h6 className="mb-0">الرسائل المباشرة خلال عام {SmsMessageYear} باجمالي {SmsMessageCountYear}</h6>
                </div>

              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
                <canvas id="chart-line-tasks" className="chart-canvas" height="170"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Home;