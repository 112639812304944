import React, { useState, useEffect } from 'react';
import {util} from 'node-forge';
import api from '../api';


const UserSmsMessage = ({ permission }) => {
  const [User, setUser] = useState('');

  const [TextSmsBalance, setTextSmsBalance] = useState(0);
  useEffect(() => {
    
    if (permission) {
      window.$('#listUserSms').dxTreeView({
        dataSource: new window.DevExpress.data.CustomStore({
          key: 'pk', // Primary key field
          load: async () => {
            try {
              return await api.get('/UserSms/');
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message) || e.response.data.detail || e.response.data.result || e.response.data || e.message;
              window.show_message(e.message, 'bg-gradient-danger', 'text-white');
              return e;
            }
          }
        }),
        elementAttr: {
          class: " p-1 m-1 "
        },
        keyExpr: 'pk',
        displayExpr: 'username',
        rootValue: 0,
        selectionMode: 'single',
        focusStateEnabled: true,
        selectOnFocus: true,
        selectByClick: true,
        selectionRequired: true,
        rtlEnabled: true,
        searchEnabled: true,
        onItemClick(e) {
          let user_id = e.itemData.pk;
          setUser(`${e.itemData.first_name} ${e.itemData.last_name}`);
          let grid = gridUserSmsMessage.dxDataGrid('instance');
          grid.option("dataSource", new window.DevExpress.data.CustomStore({
            key: 'id',
            load: async () => {
              try {
                return await api.get(`/SmsMessage/?user_id=${user_id}`);
              } catch (e) {
                e.message = (e.response.data.messages && e.response.data.messages[0].message) || e.response.data.detail || e.response.data.result || e.response.data || e.message;
                window.show_message(e.message, 'bg-gradient-danger', 'text-white');
                return e;
              }
            }
          }));
          grid.option("onContentReady",(e)=>{
            api.get(`/SystemPackageBalance/${user_id}/`).then((e)=>{
              setTextSmsBalance(e.data.text);
            }).catch((e)=>{
              window.show_message((e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message,'bg-gradient-danger','text-white');
            });
          });
          grid.refresh();
        },

      });
    } 

    let gridUserSmsMessage = window.$('#gridUserSmsMessage').dxDataGrid({
      dataSource:!permission && new window.DevExpress.data.CustomStore({
        key: 'id',
        load: async () => {
          try {
            api.get(`/SystemPackageBalance/0/`).then((e)=>{
              setTextSmsBalance(e.data.text);
            }).catch((e)=>{
              window.show_message((e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message,'bg-gradient-danger','text-white');
            });
            return await api.get(`/SmsMessage/`);
          } catch (e) {
            e.message = (e.response.data.messages && e.response.data.messages[0].message) || e.response.data.detail || e.response.data.result || e.response.data || e.message;
            window.show_message(e.message, 'bg-gradient-danger', 'text-white');
            return e;
          }
        }
      }),
      columns: [
        { dataField: 'id', caption: 'رقم التسلسل', dataType: 'number', visible: false },
        { dataField: 'phone', caption: 'رقم الهاتف', dataType: 'number' },
        { dataField: 'message', caption: 'نص الرسالة', dataType: 'string' ,
          calculateCellValue(data) {
            return util.text.utf8.decode(util.binary.base64.decode(data.message)).toString();
          },},
        { dataField: 'create_date', caption: 'التاريخ', dataType: 'datetime', },
      ],
      keyExpr: 'id',
      // export: {
      //   enabled: true,
      //   formats: ['pdf','excel'],
      //   allowExportSelectedData: true,
      // },
      filterPanel: { visible: true },
      headerFilter: { visible: true },
      filterBuilder: {
        allowHierarchicalFields: true,
      },
      filterBuilderPopup: {
        position: {
          of: window, at: 'top', my: 'top', offset: { y: 10 },
        },
      },
      filterRow: {
        visible: true,
        applyFilter: 'auto',
      },
      searchPanel: {
        visible: true,
        placeholder: 'بحث...',
      },
      // columnAutoWidth: true,
      showRowLines: true,
      showBorders: true,
      columnChooser: {
        enabled: true,
        mode: 'select',
        search: {
          enabled: true,
          editorOptions: { placeholder: 'بحث....' },
        },
        selection: {
          recursive: true,
          selectByClick: true,
          allowSelectAll: true,
        },
      },

      allowColumnResizing: true,
      showColumnLines: true,
      rowAlternationEnabled: true,
      paging: { pageSize: 5 },          // Enable paging with page size
      pager: {
        visible: true,                       // Pager configuration
        showPageSizeSelector: true,
        allowedPageSizes: [5, 10, 20, 'all'],
        showInfo: true,
      },
      // remoteOperations: false,
      groupPanel: { visible: true },
      grouping: {
        autoExpandAll: false,
      },
      allowColumnReordering: true,
    });


  }, []); // Runs once after component mounts

  return (

    <div className="col-lg-12 position-relative z-index-2">

      <div className="ms-3 p-1 my-2">
        <h3 className="mb-0 h4 font-weight-bolder"> الرسائل المباشرة </h3>
      </div>
      <div className="row mt-3">
        <div className={`col-lg-12 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-info shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">email</i>
                </div>
                <div>
                  <h4 className="mb-0">{TextSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل المباشرة </span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {permission && <>
          <div className="col-lg-4 col-md-6">
            <div className="card z-index-2 mt-4">
              <div className="card-header p-3 pt-2">
                <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                  <i className="material-symbols-rounded opacity-10">group</i>
                </div>
                <div className="d-block d-md-flex">
                  <div className="ms-auto">
                    <h6 className="mb-0">قائمة المستخدمين</h6>
                  </div>
                </div>
              </div>
              <div className="card-body p-3 pt-0">
                <div className="chart">
                  <div id="listUserSms" className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </>}
        <div className={`col-lg-${permission ? 8 : 12} col-md-6 `}>
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">table</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">

                  <h6 className="mb-0">{permission ? <> رسائل المستخدم ( {User} )</> : <>جدول الرسائل المباشرة</>}</h6>
                </div>

              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
                <div id="gridUserSmsMessage"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

export default UserSmsMessage;
