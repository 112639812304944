import React, { useState,useEffect } from 'react';
import api from '../api';


const UserSmsPackage = () => {
  const [User, setUser] = useState('');
  const [CurrentSmsBalance, setCurrentSmsBalance] = useState(0);
  const [TotalSmsBalance, setTotalSmsBalance] = useState(0);
  const [UsedSmsBalance, setUsedSmsBalance] = useState(0);
  const [VerifiedSmsBalance, setVerifiedSmsBalance] = useState(0);
  const [VerificationSmsBalance, setVerificationSmsBalance] = useState(0);
  const [TextSmsBalance, setTextSmsBalance] = useState(0);

  useEffect(() => {
    
    window.$('#listUserSms').dxTreeView({
      dataSource: new window.DevExpress.data.CustomStore({
        key: 'pk', // Primary key field
        load:async () => {
          try {
            return await api.get('/UserSms/');
        } catch (e) {
          e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
          window.show_message(e.message,'bg-gradient-danger','text-white');
          return e;
        }
      }
      }),
      elementAttr:{
        class:" p-1 m-1 "
      },
      keyExpr: 'pk',
      displayExpr: 'username',
      rootValue:0,
      selectionMode:'single',
      focusStateEnabled:true,
      selectOnFocus:true,
      selectByClick:true,
      selectionRequired:true,
      rtlEnabled:true,
      searchEnabled:true,
      onItemClick(e) {
        let user_id = e.itemData.pk;
        setUser(`${e.itemData.first_name} ${e.itemData.last_name}`);
        let grid = gridUserSmsPackage.dxDataGrid('instance');
        grid.option("dataSource", new window.DevExpress.data.CustomStore({
          key: 'id', 
          load: async () => {
            try {
              return await api.get(`/UserSmsPackage/?user_id=${user_id}`);
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message,'bg-gradient-danger','text-white');
              return e;
            }
          },
          insert: async (values) => {
            try {
              const response = await api.post('/UserSmsPackage/', JSON.stringify(values));
              response.statusText ==='OK'&& window.show_message("تمت العملية بنجاح",'bg-gradient-success','text-white');
              return response;
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message,'bg-gradient-danger','text-white');
              return e;
            }
          },
          update: async (key, values) => {
            try {
              const response = await api.put(`/UserSmsPackage/${key}/`, JSON.stringify(values));
              response.statusText ==='OK'&& window.show_message("تمت العملية بنجاح",'bg-gradient-success','text-white');
              return response;
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message,'bg-gradient-danger','text-white');
              return e;
            }
            
          },
          remove: async (key) => {
            try {
              const response = await api.delete(`/UserSmsPackage/${key}/`);
              response.statusText ==='OK'&& window.show_message("تمت العملية بنجاح",'bg-gradient-success','text-white');
              return response;
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message,'bg-gradient-danger','text-white');
              return e;
            }
          },
        }));
        grid.option("onInitNewRow",(e)=>{
          e.data.id = 0;
          e.data.user_id = user_id;
        });
        grid.option("onContentReady",(e)=>{
          api.get(`/SystemPackageBalance/${user_id}/`).then((e)=>{
            setCurrentSmsBalance(e.data.current);
            setTotalSmsBalance(e.data.total);
            setUsedSmsBalance(e.data.used);
            setVerifiedSmsBalance(e.data.verified);
            setVerificationSmsBalance(e.data.verification);
            setTextSmsBalance(e.data.text);
          }).catch((e)=>{
            window.show_message((e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message,'bg-gradient-danger','text-white');
          });
        });
        grid.refresh();
      
        
      },

    });
    let gridUserSmsPackage = window.$('#gridUserSmsPackage').dxDataGrid({
      columns: [
        { dataField: 'id', caption: 'رقم الباقة', dataType: 'number',visible:false, allowEditing: false,formItem: {visible: false,} },
        { dataField: 'user_id', caption: 'رقم المستخدم', dataType: 'number', allowEditing: false ,visible:false,formItem: {visible: false,} },
        { dataField: 'balance', caption: 'الرصيد', dataType: 'number' ,validationRules: [{ type: 'required' }],},
        { dataField: 'date', caption: 'التاريخ', dataType: 'date', format: 'yyyy-MM-dd',validationRules: [{ type: 'required' }],},
      ],
      keyExpr: 'id',
      // export: {
      //   enabled: true,
      //   formats: ['pdf','excel'],
      //   allowExportSelectedData: true,
      // },
      filterPanel: { visible: true },
      headerFilter: { visible: true },
      filterBuilder: {
        allowHierarchicalFields: true,
      },
      filterBuilderPopup: {
        position: {
          of: window, at: 'top', my: 'top', offset: { y: 10 },
        },
      },
      filterRow: {
        visible: true,
        applyFilter: 'auto',
      },
      searchPanel: {
        visible: true,
        placeholder: 'Search...',
      },
      // columnAutoWidth: true,
      showRowLines: true,
      showBorders: true,
      columnChooser: {
        enabled: true,
        mode: 'select',
        search: {
          enabled: true,
          editorOptions: { placeholder: 'Search column' },
        },
        selection: {
          recursive: true,
          selectByClick: true,
          allowSelectAll: true,
        },
      },
      
    allowColumnResizing: true,
    showColumnLines: true,
    rowAlternationEnabled: true,
      editing: {
          mode: 'form', // Editing UI: 'popup', 'form', or 'row'
          allowAdding: true,
          allowUpdating: true,
          allowDeleting: true,
      }, // Enable header filter
      paging: { pageSize: 5 },          // Enable paging with page size
      pager: {   
        visible: true,                       // Pager configuration
          showPageSizeSelector: true,
          allowedPageSizes: [5, 10, 20,'all'],
          showInfo: true,
      },
      // remoteOperations: false,
      groupPanel: { visible: true },
      grouping: {
        autoExpandAll: false,
      },
      allowColumnReordering: true,
    });


  }, []); // Runs once after component mounts

  return (

    <div className="col-lg-12 position-relative z-index-2">

      <div className="ms-3 p-1 my-2">
        <h3 className="mb-0 h4 font-weight-bolder"> باقات المستخدمين  </h3>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">message</i>
                </div>
                <div>
                  <h4 className="mb-0">{CurrentSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">الرصيد الحالي من الرسائل</span></p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">sms</i>
                </div>
                <div>
                  <h4 className="mb-0">{TotalSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل الممنوحة </span></p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">send</i>
                </div>
                <div>
                  <h4 className="mb-0">{UsedSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي رسائل التي تم استهلاكها </span></p>
            </div>
          </div>
        </div>
        
        
      </div>
      
      {/* <div className="row mt-3">
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-primary shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">verified</i>
                </div>
                <div>
                  <h4 className="mb-0">{VerificationSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder"> اجمالي رسائل التحقق </span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-success shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">check</i>
                </div>
                <div>
                  <h4 className="mb-0">{VerifiedSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي رسائل التحقق الناجحة </span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-warning shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">close</i>
                </div>
                <div>
                  <h4 className="mb-0">{VerificationSmsBalance-VerifiedSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي رسائل التحقق الفاشلة </span></p>
            </div>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-sm-6`}>
          <div className="card  mb-2">
            <div className="card-header p-2 ps-3">
              <div className="d-flex justify-content-between">
                <div className="icon icon-md icon-shape bg-gradient-info shadow-dark shadow text-center border-radius-lg">
                  <i className="material-symbols-rounded opacity-10">email</i>
                </div>
                <div>
                  <h4 className="mb-0">{TextSmsBalance}</h4>
                </div>
              </div>
            </div>
            <hr className="dark horizontal my-0" />
            <div className="card-footer p-2 ps-3">
              <p className="mb-0 text-sm"><span className="text-success font-weight-bolder">اجمالي الرسائل المباشرة </span></p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">

        <div className="col-lg-4 col-md-6">
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">group</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">
                  <h6 className="mb-0">قائمة المستخدمين</h6>
                </div>
              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
                <div id="listUserSms" className="ms-auto"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 ">
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">table</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">
                  <h6 className="mb-0"> باقات المستخدم ( {User} )</h6>
                </div>

              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
                <div id="gridUserSmsPackage"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

export default UserSmsPackage;
