import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import app_icon from './app_icon.png';
// import axios from 'axios';
import api from '../api';

const Layout = ({ onLogout, permission, container }) => {
    const location = useLocation();
    const [activePath, setActivePath] = useState(location.pathname);
    const [isInitSystemOpen, setIsInitSystemOpen] = useState(false);
    const [isSmsSystemOpen, setSmsSystemOpen] = useState(false);
    // State to toggle sidebar visibility
    const [showSidebar, setShowSidebar] = useState(true);

    // Function to toggle sidebar visibility
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };
    // State to toggle ShowSettings visibility
    const [showSettings, setShowSettings] = useState(true);

    // Function to toggle sidebar visibility
    const toggleSettings = () => {
        setShowSettings(!showSettings);
    };


    useEffect(() => {
        // Update active path whenever the location changes
        setActivePath(location.pathname);

        // Automatically open the "initSystem" collapse if we are on a related page
        if (location.pathname.includes("/Users") || location.pathname.includes("/UsersGroups")) {
            setIsInitSystemOpen(true);
        } else {
            setIsInitSystemOpen(false);
        }
        if (location.pathname.includes("/SmsPackage") || location.pathname.includes("/UserSmsPackage") || location.pathname.includes("/UserSmsVerification") || location.pathname.includes("/UserSmsMessage")) {
            setSmsSystemOpen(true);
        } else {
            setSmsSystemOpen(false);
        }


    }, [location]);

    const handleToggleInitSystem = () => {
        setIsInitSystemOpen(!isInitSystemOpen);
    };
    const handleToggleSmsSystem = () => {
        setSmsSystemOpen(!isSmsSystemOpen);
    };

    // const [req, setreq] = useState();

    // const sendSMS = () => {
    //     let Server = 'https://sms.tastye.net/';
    //     const send = axios.create({
    //         baseURL: Server,
    //         headers: { 'Content-Type': 'application/json' },
    //     });
    //     send.interceptors.request.use((config) => {
    //         config.headers['Authorization'] = `Basic ${btoa(`username:password`)}`;
    //         return config;
    //     });
    //     let phone = prompt('phone number');
    //     let message = prompt('message text');
    //     let res = send.post('/SendSmsMessage', { phone: phone, message: message });
    //     res.then((e) => {
    //         alert(e.data.result);
    //     });
    // }
    // const sendVerifiy = () => {
    //     let Server = 'https://sms.tastye.net/';
    //     const send = axios.create({
    //         baseURL: Server,
    //         headers: { 'Content-Type': 'application/json' },
    //     });
    //     send.interceptors.request.use((config) => {
    //         config.headers['Authorization'] = `Basic ${btoa(`username:password`)}`;
    //         return config;
    //     });
    //     let phone = prompt('phone number');
    //     if (phone !== null && phone !== "") {
    //         let res = send.post('/SendSmsVerification', { phone: phone });
    //         res.then((e) => {
    //             if (e.data.result === "Success") {
    //                 setreq(e);
    //             }
    //             else {
    //                 alert(e.data.result);
    //             }
    //         });
    //     }
    // }
    // const checkVerifiy = () => {
    //     let Server = 'https://sms.tastye.net/';
    //     const check = axios.create({
    //         baseURL: Server,
    //         headers: { 'Content-Type': 'application/json' },
    //     });
    //     check.interceptors.request.use((config) => {
    //         config.headers['Authorization'] = `Token ${req.data.token}`;
    //         return config;
    //     });
    //     let verification_code = prompt('verification code');
    //     if (verification_code !== null && verification_code !== "") {
    //         let res2 = check.post(`/CheckSmsVerification/${req.data.path}`, { verification_code: verification_code });
    //         res2.then((e) => {
    //             alert(e.data.result);
    //         });
    //     }
    // };

    useEffect(() => {
        const dark = document.getElementById("dark-version");
        const theme = document.getElementById("theme-link");
        if (localStorage.getItem("darkMode") === 'true') {
            theme.href = "/Lib/css/dx.dark.css";
            dark.checked = true;
            dark.setAttribute('data-class', "bg-gradient-dark");
            document.body.classList.add("dark-version");
            // window.darkMode(dark);
        } else {
            theme.href = "/Lib/css/dx.light.css";
            dark.checked = false;
            dark.setAttribute('data-class', "bg-dark");
            document.body.classList.remove("dark-version");
        }
        window.sidebarType(dark);
        dark.onclick = (e) => {
            localStorage.setItem("darkMode", e.target.checked);
            if (localStorage.getItem("darkMode") === 'true') {
                theme.href = "/Lib/css/dx.dark.css";
                dark.setAttribute('data-class', "bg-gradient-dark");
                window.sidebarType(dark);
                document.body.classList.add("dark-version");
            } else {
                theme.href = "/Lib/css/dx.light.css";
                dark.setAttribute('data-class', "bg-dark");
                dark.checked = false;
                document.body.classList.remove("dark-version");
            }

        };
        dark.setAttribute('data-color', localStorage.getItem("sidebarColor") || 'primary');
        window.sidebarColor(dark);
        // dark.setAttribute('data-class', localStorage.getItem("sidebarType"));
        // window.sidebarType(dark);
        const navbarFix = document.getElementById("navbarFixed");
        if (localStorage.getItem("navbarFixed") === 'true') {
            navbarFix.checked = true;
            window.navbarFixed(navbarFix);
        } else { navbarFix.checked = false; }
        const navbarMini = document.getElementById("navbarMinimize");
        if (localStorage.getItem("navbarMinimize") === 'true') {
            navbarMini.checked = true;
            window.navbarMinimize(navbarMini);
        } else { navbarMini.checked = false; }

    }, []);
    return (
        <div className={`g-sidenav-show rtl ${showSidebar ? '' : 'g-sidenav-pinned g-sidenav-hidden'}`}>
            <aside className={`sidenav navbar navbar-vertical navbar-expand-xs border-radius-lg fixed-end me-2 rotate-caret bg-dark my-2 ${showSidebar || localStorage.getItem("navbarMinimize") === 'true' ? '' : 'ps'} ps__rtl ps--active-y ps--scrolling-y `} id="sidenav-main">
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                    <Link to="/" className={`navbar-brand px-4 py-3 m-0 text-white ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                        <img src={app_icon} className="navbar-brand-img" width="26" height="26" alt="main_logo" />
                        <span className="ms-1 text-sm  p-1">نظام SMS API</span>
                    </Link>
                </div>
                <hr className="horizontal dark mt-0 mb-2" />
                <div className={`collapse navbar-collapse px-0 w-auto h-auto ps`} id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className={`nav-item ${activePath === "/" ? " active" : ""}`}>
                            <Link to="/" className={`nav-link ps-0 ${activePath === "/" ? " active" : ""} text-white ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                                <i className="sidenav-mini-icon material-symbols-rounded opacity-5">space_dashboard</i>
                                <span className="sidenav-normal ms-1 ps-1 p-1">الرئيسية </span>
                            </Link>
                        </li>
                        {permission &&
                            <>
                                <li className="nav-item">
                                    <Link
                                        onClick={handleToggleInitSystem}
                                        className={`nav-link ${isInitSystemOpen ? "active" : ""} text-white ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}
                                        aria-expanded={isInitSystemOpen}
                                        data-bs-toggle="collapse"
                                        aria-controls="initSystem" role="button"
                                    >
                                        <i className="sidenav-mini-icon material-symbols-rounded opacity-5">settings</i>
                                        <span className="nav-link-text ms-1 ps-1 p-1"> تهيئة النظام </span>
                                    </Link>
                                    <div className={`collapse ${isInitSystemOpen ? "show" : ""}`} id="initSystem">
                                        <ul className="nav pe-0">
                                            {/* <li className={`nav-item ${activePath === "/UsersGroups" ? "active" : ""}`}>
                                        <Link to="/UsersGroups" className={`nav-link text-white ${activePath === "/UsersGroups" ? "active" : ""}`}>
                                            <span className="sidenav-mini-icon material-symbols-rounded ">groups</span>
                                            <span className="sidenav-normal ms-1 ps-1 p-1"> مجموعات المستخدمين </span>
                                        </Link>
                                    </li> */}
                                            <li className={`nav-item ${activePath === "/Users" ? "active" : ""}`}>
                                                <Link to="/Users" className={`nav-link ps-0 text-white ${activePath === "/Users" ? "active" : ""} ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                                                    <span className="sidenav-mini-icon material-symbols-rounded ">person_add</span>
                                                    <span className="sidenav-normal ms-1 ps-1 p-1"> المستخدمين </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </>
                        }
                        <li className="nav-item">
                            <Link
                                onClick={handleToggleSmsSystem}
                                className={`nav-link  text-white ${isSmsSystemOpen ? "active" : ""} ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}
                                aria-expanded={isSmsSystemOpen}
                                data-bs-toggle="collapse"
                                aria-controls="SmsSystem" role="button"
                            >
                                <i className="sidenav-mini-icon material-symbols-rounded opacity-5">message</i>
                                <span className="nav-link-text ms-1 ps-1 p-1"> نظام الرسائل </span>
                            </Link>
                            <div className={`collapse ${isSmsSystemOpen ? "show" : ""}`} id="SmsSystem">
                                <ul className="nav pe-0">
                                    {permission &&
                                        <>
                                            <li className={`nav-item ${activePath === "/SmsPackage" ? "active" : ""}`}>
                                                <Link to="/SmsPackage" className={`nav-link ps-0 text-white ${activePath === "/SmsPackage" ? "active" : ""} ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                                                    <span className="sidenav-mini-icon material-symbols-rounded ">package</span>
                                                    <span className="sidenav-normal ms-1 ps-1 p-1"> باقات الشركة</span>
                                                </Link>
                                            </li>
                                            <li className={`nav-item ${activePath === "/UserSmsPackage" ? "active" : ""}`}>
                                                <Link to="/UserSmsPackage" className={`nav-link ps-0 text-white ${activePath === "/UserSmsPackage" ? "active" : ""} ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                                                    <span className="sidenav-mini-icon material-symbols-rounded ">sms</span>
                                                    <span className="sidenav-normal ms-1 ps-1 p-1"> باقات المستخدمين </span>
                                                </Link>
                                            </li>
                                        </>
                                    }

                                    <li className={`nav-item ${activePath === "/UserSmsVerification" ? "active" : ""}`}>
                                        <Link to="/UserSmsVerification" className={`nav-link ps-0 text-white ${activePath === "/UserSmsVerification" ? "active" : ""} ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                                            <span className="sidenav-mini-icon material-symbols-rounded ">verified</span>
                                            <span className="sidenav-normal ms-1 ps-1 p-1"> رسائل التحقق </span>
                                        </Link>
                                    </li>
                                    <li className={`nav-item ${activePath === "/UserSmsMessage" ? "active" : ""}`}>
                                        <Link to="/UserSmsMessage" className={`nav-link ps-0 text-white ${activePath === "/UserSmsMessage" ? "active" : ""} ${localStorage.getItem("darkMode") === 'true' ? "text-white" : "text-dark"}`}>
                                            <span className="sidenav-mini-icon material-symbols-rounded ">email</span>
                                            <span className="sidenav-normal ms-1 ps-1 p-1"> الرسائل المباشرة </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                    </ul>
                </div>
                <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}><div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div></div>
                <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}><div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "0px" }}></div></div>
            </aside>
            <main className={`main-content position-relative max-height-vh-100 h-100 border-radius-lg ps__rtl ps--active-y ps--scrolling-y`}>
                <nav className="navbar navbar-main navbar-expand-lg position-sticky mt-2 top-1 px-0 py-1 mx-4 border-radius-lg z-index-sticky  shadow-none right-auto"
                    id="navbarBlur"
                    data-scroll="true">

                    <div className="container-fluid py-1 px-2">
                        <div className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                            <Link to="#" className="nav-link text-body p-0 text-dark" onClick={toggleSidebar}>
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                </div>
                            </Link>
                        </div>

                        <div className="ms-md-auto d-xl-none pe-md-auto d-flex align-items-center ">
                            <Link to="/" className={`navbar-brand text-dark ${localStorage.getItem("darkMode") === 'true' ? (localStorage.getItem("navbarFixed") === 'true' ? "text-dark" : "text-white") : "text-dark"}`}>
                                <img src={app_icon} className="navbar-brand-img" width="26" height="26" alt="main_logo" />
                                <span className="ms-1 text-sm text-body text-dark p-1">نظام SMS API</span>
                            </Link>
                        </div>
                        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 w-auto" id="navbar">

                            <div className="ms-md-auto d-xl-block pe-md-3 d-flex align-items-center "></div>

                            <ul className="navbar-nav justify-content-end pt-1">

                                <li className="nav-item">
                                    <Link to="#" className={`nav-link py-0 px-1 line-height-0 text-body text-dark ${localStorage.getItem("darkMode") === 'true' ? (localStorage.getItem("navbarFixed") === 'true' ? "text-dark" : "text-white") : "text-dark"}`} onClick={toggleSettings}>
                                        <i className="material-symbols-rounded fixed-plugin-button-nav">settings</i>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown py-0 pe-3 ">
                                    <Link
                                        to="#"
                                        className={`nav-link py-0 px-1 position-relative text-dark line-height-0 ${localStorage.getItem("darkMode") === 'true' ? (localStorage.getItem("navbarFixed") === 'true' ? "text-dark" : "text-white") : "text-dark"}`}
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <i className="material-symbols-rounded">person</i>
                                        <span className="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-success border border-white small py-1 px-1">
                                            <span className="small"></span>
                                            <span className="visually-hidden"></span>
                                        </span>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end p-2 me-xl-n8 me-sm-n7 me-md-n7 me-lg-n6 " aria-labelledby="dropdownMenuButton">
                                        {/* <li className="mb-2">
                                            <Link className="dropdown-item border-radius-md text-body text-dark" to="/RestPassword">
                                                <div className="d-flex align-items-center py-1">
                                                    <span className="material-symbols-rounded">lock</span>
                                                    <div className="me-2">
                                                        <h6 className="text-sm font-weight-normal my-auto">تغيير كلمة المرور</h6>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li> */}
                                        <li className="mb-2">
                                            <Link className="dropdown-item border-radius-md text-body text-dark" to="#" onClick={onLogout}>
                                                <div className="d-flex align-items-center py-1">
                                                    <span className="material-symbols-rounded">logout</span>
                                                    <div className="me-2">
                                                        <h6 className="text-sm font-weight-normal my-auto">تسجيل الخروج</h6>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="#" className={`nav-link py-0 px-1 line-height-0 text-dark ${localStorage.getItem("darkMode") === 'true' ? (localStorage.getItem("navbarFixed") === 'true' ? "text-dark" : "text-white") : "text-dark"}`} onClick={showAlert}>
                                        <i className="material-symbols-rounded fixed-plugin-button-nav">sms</i>
                                    </Link>
                                </li> */}
                                <li className="nav-item d-xl-none pe-3 d-flex align-items-center">

                                    <button className="nav-link text-body p-0 text-dark navbar-toggler shadow-none d-sm-none pb-1" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon">
                                            <span className="navbar-toggler-bar bar1"></span>
                                            <span className="navbar-toggler-bar bar2"></span>
                                            <span className="navbar-toggler-bar bar3"></span>
                                        </span>
                                    </button>
                                    <Link to="#" className="nav-link text-body p-0 text-dark d-sm-block d-none pb-1" id="iconNavbarSidenav" onClick={toggleSidebar}>
                                        <div className="sidenav-toggler-inner">
                                            <i className="sidenav-toggler-line"></i>
                                            <i className="sidenav-toggler-line"></i>
                                            <i className="sidenav-toggler-line"></i>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="collapse  d-xl-none w-auto" id="navigation">
                            <ul className="navbar-nav   px-0 ">
                                <li className={`nav-item ${activePath === "/" ? " active" : ""}`}>
                                    <Link to="/" className={`nav-link d-flex align-items-center text-body text-dark px-1 ${activePath === "/" ? " active" : ""}`}>
                                        <i className="sidenav-mini-icon material-symbols-rounded opacity-5">space_dashboard</i>
                                        <span className="sidenav-normal text-body text-dark ms-1 ps-1 p-1">الرئيسية </span>
                                    </Link>
                                </li>

                                {permission &&
                                    <>
                                        <li className="dropdown dropdown-hover ">
                                            <Link
                                                onClick={handleToggleInitSystem}
                                                className={`nav-link d-flex align-items-center text-body text-dark px-1`}
                                                aria-expanded={isInitSystemOpen}
                                                aria-controls="initSystem" role="button"
                                                id="dropdownMenuPages" data-bs-toggle="dropdown"
                                            >
                                                <i className="sidenav-mini-icon material-symbols-rounded opacity-5">settings</i>
                                                <span className="nav-link-text text-body text-dark ms-1 ps-1 p-1"> تهيئة النظام </span>
                                            </Link>
                                            <div className={`dropdown-menu dropdown-menu-animation border-radius-lg top-50 h-auto`} aria-labelledby="dropdownMenuPages" id="initSystem">
                                                <ul className="dropdown-header text-dark font-weight-bolder align-items-center px-1 py-1">
                                                    <li className={`dropdown-item border-radius-md `}>
                                                        <Link to="/Users" className={`text-body text-dark`}>
                                                            <span className="sidenav-mini-icon material-symbols-rounded ">person_add</span>
                                                            <span className="sidenav-normal text-body text-dark ms-1 ps-1 p-1"> المستخدمين </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li className="dropdown dropdown-hover ">
                                            <Link
                                                onClick={handleToggleInitSystem}
                                                className={`nav-link d-flex align-items-center text-body text-dark px-1`}
                                                aria-expanded={isSmsSystemOpen}
                                                aria-controls="SmsSystem" role="button"
                                                id="dropdownMenuPages" data-bs-toggle="dropdown"
                                            >
                                                <i className="sidenav-mini-icon material-symbols-rounded opacity-5">message</i>
                                                <span className="nav-link-text text-body text-dark ms-1 ps-1 p-1"> نظام الرسائل </span>
                                            </Link>
                                            <div className={`dropdown-menu dropdown-menu-animation border-radius-lg top-50 h-auto`} aria-labelledby="dropdownMenuPages" id="SmsSystem">
                                                <ul className="dropdown-header text-dark font-weight-bolder align-items-center px-1 py-1 ">
                                                    <li className={`dropdown-item border-radius-md ${activePath === "/SmsPackage" ? "active" : ""}`}>
                                                        <Link to="/SmsPackage" className={`nav-link text-body text-dark ${activePath === "/SmsPackage" ? "active" : ""} `}>
                                                            <span className="sidenav-mini-icon material-symbols-rounded ">package</span>
                                                            <span className="sidenav-normal text-body text-dark ms-1 ps-1 p-1"> باقات الشركة</span>
                                                        </Link>
                                                    </li>
                                                    <li className={`dropdown-item border-radius-md ${activePath === "/UserSmsPackage" ? "active" : ""}`}>
                                                        <Link to="/UserSmsPackage" className={`nav-link text-body text-dark ${activePath === "/UserSmsPackage" ? "active" : ""} `}>
                                                            <span className="sidenav-mini-icon material-symbols-rounded ">sms</span>
                                                            <span className="sidenav-normal text-body text-dark ms-1 ps-1 p-1"> باقات المستخدمين </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>

                    </div>
                </nav>
                <div className=" position-sticky mt-2 top-1 px-0 py-1 mx-4 border-radius-lg shadow-none right-auto ">
                    <div className="row">
                        <div className="col-lg-12 position-relative z-index-2">
                            {container}
                        </div>
                    </div>
                    <div className="position-fixed bottom-1 z-index-2">
                        <div className="toast fade hide p-2 mt-2" role="alert" aria-live="assertive" id="dangerToast" aria-atomic="true">
                            <div className="toast-body font-weight-bold">
                                <i className="fas fa-times text-md cursor-pointer ms-2" data-bs-dismiss="toast" aria-label="Close"></i>  <span id="error-message"></span>
                            </div>
                        </div>
                    </div>
                    <footer className="footer py-4  ">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        © <script>
                                            document.write(new Date().getFullYear())
                                        </script> ITS Inc
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}><div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div></div>
                <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}><div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "0px" }}></div></div>
            </main>


            <div className={`fixed-plugin ${showSettings ? 'ps' : 'ps show'} `}>
                <Link to="#" className={`fixed-plugin-button text-white ${localStorage.getItem("darkMode") === 'true' ? "bg-gradient-dark" : "bg-dark"} position-fixed px-3 py-2`} onClick={toggleSettings}>
                    <i className="material-symbols-rounded py-2">settings</i>
                </Link>
                <div className="card shadow w-auto h-auto " style={{ borderRadius: '0.5rem', 'margin': '0.75rem' }}>
                    <div className="card-header pb-0 pt-3">
                        <div className="float-start">
                            <h5 className="mt-0 mb-0">إعدادات المظهر</h5>
                        </div>
                        <div className="float-end mt-0">
                            <button className={` btn bg-gradient-dark m-1 p-1 `} onClick={toggleSettings}>
                                <i className={`material-symbols-rounded`}>clear</i>
                            </button>
                        </div>

                    </div>
                    <hr className="horizontal dark my-1" />
                    <div className="card-body pt-sm-3 pt-0">

                        <div>
                            <h6 className="mb-0">لون مكونات القائمة</h6>
                        </div>
                        <Link to="#" className="switch-trigger background-color">
                            <div className="badge-colors my-2 text-start">
                                <span className={`badge filter bg-gradient-primary p-1 m-1 ${localStorage.getItem("sidebarColor") === 'primary' ? "active" : ""}`} data-color="primary" onClick={(e) => { window.sidebarColor(e.target); localStorage.setItem("sidebarColor", "primary"); }}></span>
                                <span className={`badge filter bg-gradient-dark p-1 m-1 ${localStorage.getItem("sidebarColor") === 'dark' ? "active" : ""}`} data-color="dark" onClick={(e) => { window.sidebarColor(e.target); localStorage.setItem("sidebarColor", "dark"); }}></span>
                                <span className={`badge filter bg-gradient-info p-1 m-1 ${localStorage.getItem("sidebarColor") === 'info' ? "active" : ""}`} data-color="info" onClick={(e) => { window.sidebarColor(e.target); localStorage.setItem("sidebarColor", "info"); }}></span>
                                <span className={`badge filter bg-gradient-success p-1 m-1 ${localStorage.getItem("sidebarColor") === 'success' ? "active" : ""}`} data-color="success" onClick={(e) => { window.sidebarColor(e.target); localStorage.setItem("sidebarColor", "success"); }}></span>
                                <span className={`badge filter bg-gradient-warning p-1 m-1 ${localStorage.getItem("sidebarColor") === 'warning' ? "active" : ""}`} data-color="warning" onClick={(e) => { window.sidebarColor(e.target); localStorage.setItem("sidebarColor", "warning"); }}></span>
                                <span className={`badge filter bg-gradient-danger p-1 m-1 ${localStorage.getItem("sidebarColor") === 'danger' ? "active" : ""}`} data-color="danger" onClick={(e) => { window.sidebarColor(e.target); localStorage.setItem("sidebarColor", "danger"); }} ></span>
                            </div>
                        </Link>

                        {/* <div className="mt-3">
                            <h6 className="mb-0">لون خلفية القائمة</h6>
                            <br />
                        </div>
                        <div className="d-flex">

                       
                            <button className={`btn bg-gradient-dark px-3 mb-2 m-1 ${localStorage.getItem("sidebarType") === 'bg-gradient-dark' ? "active" : ""}`} data-class="bg-gradient-dark" onClick={(e) => { window.sidebarType(e.target); localStorage.setItem("sidebarType", "bg-gradient-dark"); }}>داكن</button>
                            <button className={`btn bg-gradient-dark px-3 mb-2 m-1 ${localStorage.getItem("sidebarType") === 'bg-transparent' ? "active" : ""}`} data-class="bg-transparent" onClick={(e) => { window.sidebarType(e.target); localStorage.setItem("sidebarType", "bg-transparent"); }}>شفاف</button>
                            <button className={`btn bg-gradient-dark px-3 mb-2 m-1 ${localStorage.getItem("sidebarType") === 'bg-white' ? "active" : ""}`} data-class="bg-white" onClick={(e) => { window.sidebarType(e.target); localStorage.setItem("sidebarType", "bg-white"); }} >فاتح</button>
                        </div> */}

                        <div className="mt-3 d-flex">
                            <h6 className="mb-0 ms-auto"> تثبيت القائمة العليا </h6>
                            <div className="form-check form-switch ps-0 me-auto my-auto">
                                <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" onClick={(e) => { window.navbarFixed(e.target); localStorage.setItem("navbarFixed", e.target.checked); }} />
                            </div>
                        </div>
                        <hr className="horizontal dark my-3" />
                        <div className="mt-2 d-flex">
                            <h6 className="mb-0 ms-auto">تصغير القائمة</h6>
                            <div className="form-check form-switch ps-0 me-auto my-auto">
                                <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarMinimize" onClick={(e) => { window.navbarMinimize(e.target); localStorage.setItem("navbarMinimize", e.target.checked); }} />
                            </div>
                        </div>
                        <hr className="horizontal dark my-3" />
                        <div className="mt-2 d-flex">
                            <h6 className="mb-0 ms-auto">الوضع الداكن</h6>
                            <div className="form-check form-switch ps-0 me-auto my-auto">
                                <input className="form-check-input mt-1 ms-auto" type="checkbox" data-class="bg-gradient-dark" data-color="bg-gradient-primary" id="dark-version" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}><div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div></div>
                <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}><div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "0px" }}></div></div>
            </div>

        </div>
    );
};

export default Layout;
