import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Auth/Login';
import Layout from './Layout/Layout';
import Home from './Layout/Home';
import SmsPackage from './SMS/SmsPackage';
import UserSmsPackage from './SMS/UserSmsPackage';
import UserSmsVerification from './SMS/UserSmsVerification';
import UserSmsMessage from './SMS/UserSmsMessage';
import UserSms from './SMS/UserSms';
import api from './api';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSuperUserPermission, setIsSuperUserPermission] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setIsAuthenticated(!!token);
    let permission = localStorage.getItem('permission');
    permission = JSON.parse(permission) || false;
    setIsSuperUserPermission(permission);
  }, []);
  const refreshSession = () => {
    // window.material.showSwal('warning-message-and-cancel');
    // const s = window.Swal;
    let check = false;

    let s = window.Swal.mixin({
      customClass: {
        confirmButton: "btn bg-gradient-success m-1",
        cancelButton: "btn bg-gradient-danger m-1"
      },
      buttonsStyling: false
    }).fire({
      title: 'سيتم تسجيل الخروج بعد اقل من دقيقة !',
      text: 'أنتهت مدة هذه الجلسة هل تريد أستمرار الجلسة ؟',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: '<i class="fa fa-refresh"></i> تحديث الجلسة',
      cancelButtonText: '<i class="fa fa-door"></i> تسجيل الخروج',
      timer: 6000,
      timerProgressBar: true,
    }).then(r => {
      localStorage.removeItem('access_token');
      if (r.isConfirmed) {
        let e;
        window.Swal.fire({
          title: "جاري تحديث الجلسة",
          html: "<b></b>",
          timer: 2e3,
          timerProgressBar: true,
          didOpen: () => {
            window.Swal.showLoading();
            e = window.setInterval(() => {
              var e = window.Swal.getHtmlContainer();
              e && (e = e.querySelector("b")) && (e.textContent = window.Swal.getTimerLeft())
            }
              , 100);
          }
          ,
          willClose: () => {
            window.clearInterval(e)
          }
        })
          .then(a => {
            if (a.dismiss) {
              const refresh = localStorage.getItem('refresh_token');
              api.post('/token/refresh', { refresh }).then((e) => {
                localStorage.setItem('access_token', e.data.access);
                localStorage.setItem('refresh_token', e.data.refresh);
                check = true;
              }).catch((e) => {
                window.show_message(e.response.data.detail || e.response.data.result || e.response.data || e.message, 'bg-gradient-danger', 'text.white');
              });

            }
          }
          );
      } else {
        handleLogout();
      }

    }
    );
    setInterval(() => {
      if (!check) {
        handleLogout();
      }
    }, 60000);
    // return true;

  };
  const handleLogin = (UserPermission) => {
    setIsSuperUserPermission(UserPermission);
    setIsAuthenticated(true);
    setInterval(() => {
      localStorage.getItem('refresh_token') && refreshSession();
    }, 300000);
  };


  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    localStorage.removeItem('permission');
    setIsSuperUserPermission(false);


  };



  return (
    <Router>

      {isAuthenticated ? (
        <Layout onLogout={handleLogout} permission={isSuperUserPermission} container={
          <Routes>
            <Route path="/" element={<Home permission={isSuperUserPermission} />} />
            {isSuperUserPermission &&
              <>
                <Route path="/Users" element={<UserSms />} />
                <Route path="/SmsPackage" element={<SmsPackage />} />
                <Route path="/UserSmsPackage" element={<UserSmsPackage />} />
              </>
            }
            <Route path="/UserSmsVerification" element={<UserSmsVerification permission={isSuperUserPermission} />} />
            <Route path="/UserSmsMessage" element={<UserSmsMessage permission={isSuperUserPermission} />} />
          </Routes>
        } />
      ) : (
        <>
          <Login onLogin={handleLogin} />
        </>
      )}
    </Router>
  );
}

export default App;
